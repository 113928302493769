<template>
    <div class="cart">
        <h3>Inspection Process</h3>
        <h6>送检流程</h6>
        <img src="@/assets/inspection.png"/>
    </div>
</template>
<script>
export default {
    name: 'inspection'
}
</script>
<style scoped>
.cart {
    text-align: center;
    overflow: auto;
}
h6 {
    margin-bottom: 18px;
}
</style>